import React, { Component } from "react";
import "./About.css";
import CV_IVAN_Tasevski from "../../../src/CV_IVAN_Tasevski.pdf";
import cv_icon_download from "../../../src/cv_icon_download.png";
import mypic from "../../../src/mypic.jpg";
import macedonia_flag from "../../../src/macedonia_flag.gif";

class About extends Component {
  state = {};

  render() {
    return (
      <div className="about-section" id="about">
        {/* <span className="border-right-green"></span> */}
        <h1> About </h1>
        <div className="about-container">
          <div className="title-about">
            <div className="left-line"></div>
            <div className="content">
              <h2>
                Graphic Designer &
                <br />
                Full Stack Developer
              </h2>
              <h3>In Skopje, Macedonia</h3>
              <img
                src={macedonia_flag}
                width="30px"
                alt="macedonian_flag"
              ></img>
              <br />
              <img id="mypic" src={mypic} width="220px" alt="picture_of_me" />
              <br />
              <a
                id="cv_icon_download"
                href={CV_IVAN_Tasevski}
                download
                title="Download CV"
              >
                <img src={cv_icon_download} alt="cv_icon" />
              </a>
            </div>
          </div>
          <div className="description">
            <div className="text">
              <p>
                <br />
                <br />
                Graphic Designer & Newly Become Full Stack Web Developer <br />
                with extensive experience in multimedia, marketing and print
                design.
                <br />
                <br />
                I have been developing and experimenting with my skills, that’s
                why my style is defined by my use of color, graphic
                compositions, and constructed visuals.
                <br />
                <br />I take pride in delivering the BEST service to my clients
                <br />
                <br />
                Showing some of my work in the section below.
              </p>
            </div>
            <div className="right-line"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
