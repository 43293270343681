import React from "react";

import "./Footer.css";

import gitlink from "../../../src/gitlink.svg";
import email from "../../../src/email.svg";

import { Link } from "react-scroll";

function Footer(props) {
  return (
    <div className="footer" id="footer">
      <div className="to-top">
        <Link to="first" spy={true} smooth={true} offset={-70} duration={500}>
          <p className="dot"></p>
        </Link>
        <br />
      </div>
      <div className="bottom-sec">
        <div className="contact">
          <a
            className="cont gitlink"
            href="https://github.com/baivanco"
            target="_blank"
            rel="noopener noreferrer"
            title="Link to my GitHub"
          >
            <img id="git-icon" src={gitlink} width="30px" alt="git_icon_link" />
          </a>
          <a
            className="cont email"
            href="mailto:baivanco@gmail.com? subject=Job Offer"
            title="Send me email"
          >
            <img
              id="email-icon"
              src={email}
              width="40px"
              alt="email_icon_link"
            />
          </a>
        </div>

        <a className="tel-num" href="tel:+38977394737">
          <h2>+389.77.394.737</h2>
        </a>

        <span id="rights">All Rights Reserved © Ivan Tasevski 2019 </span>
      </div>
    </div>
  );
}

export default Footer;
