import React, { Component } from "react";
import IvanTasevski from "../../IvanTasevski.gif";

import "./First.css";
import { Link } from "react-scroll";

class First extends Component {
  state = {
    backView: false
  };

  render() {
    return (
      <div className="first-section" id="first">
        <img className="logo-name" src={IvanTasevski} width="230" alt="logo" />

        <div className="scroll-show">
          <Link to="main" spy={true} smooth={true} offset={-70} duration={500}>
            <span id="arrow_down"></span>
          </Link>
          {/* <span className="border-right"></span> */}
        </div>
      </div>
    );
  }
}
export default First;
