import React, { Component } from "react";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./Portfolio.css";
import VizSensor from "react-visibility-sensor";

//package images
import unicorn from "../../../src/unicorn.jpg";
import hempoil from "../../../src/hempoil.jpg";
import dylonic from "../../../src/dylonic.jpg";
import dapper from "../../../src/dapper.jpg";
import rviita from "../../../src/rviita.jpg";
import edisonbulb from "../../../src/edisonbulb.jpg";
import dsauto from "../../../src/dsauto.jpg";
import nfstea from "../../../src/nfstea.jpg";
import brickshield from "../../../src/brickshield.jpg";
import kaisersax from "../../../src/kaisersax.jpg";
import alco from "../../../src/alco.jpg";
import myrepublik from "../../../src/myrepublik.jpg";
import sporttape from "../../../src/sporttape.jpg";
import awesome from "../../../src/awesome.jpg";
//logo images
import ess from "../../../src/ess.jpg";
import valleybrook from "../../../src/valleybrook.jpg";
import vibrand from "../../../src/vibrand.jpg";
import stelle from "../../../src/stelle.jpg";
import table33 from "../../../src/table33.jpg";
import happyfamily from "../../../src/happyfamily.jpg";
import cann from "../../../src/cann.jpg";
import primehook from "../../../src/primehook.jpg";
import trinity from "../../../src/trinity.jpg";
import conveyor from "../../../src/conveyor.jpg";
import gulvhallen from "../../../src/gulvhallen.jpg";
import visualq from "../../../src/visualq.jpg";
import device from "../../../src/device.jpg";
import magner from "../../../src/magner.jpg";
import tamnun from "../../../src/tamnun.jpg";

//web design images
import avainsu from "../../../src/avainsu.jpg";
import moov from "../../../src/moov.jpg";
import proels from "../../../src/proels.jpg";
import metus from "../../../src/metus.jpg";

//web applications images
import expenses from "../../../src/expenses.jpg";
import courses from "../../../src/courses.jpg";
import dogapi from "../../../src/dogapi.jpg";
import magic from "../../../src/magic_the_gathering.jpg";

import backButton from "../../../src/backbutton.png";
import nextButton from "../../../src/nextbutton.png";

class Portfolio extends Component {
  state = {
    sectionViz: false,
    section2Viz: false,
    section3Viz: false,
    section4Viz: false,
  };

  render() {
    return (
      <div className="portfolio-section" id="portfolio">
        <h1 id="title-port"> Portfolio </h1>
        <VizSensor
          partialVisibility
          onChange={(isVisible) => {
            this.setState({ sectionViz: isVisible });
          }}
        >
          <div
            className="port_section package"
            style={{
              opacity: this.state.sectionViz ? 1 : 0,
              transition: "opacity 800ms linear",
            }}
          >
            <h1>
              Packaging & <br />
              Label
            </h1>
            <CarouselProvider
              className="carousel-package"
              naturalSlideWidth={200}
              naturalSlideHeight={100}
              totalSlides={14}
            >
              <ButtonBack>
                <img src={backButton} alt="" />
              </ButtonBack>

              <ButtonNext>
                <img src={nextButton} alt="" />
              </ButtonNext>
              <Slider>
                <Slide index={0}>
                  <img src={unicorn} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Magical Unicorn Poo</h2>
                    <p>
                      A package for a magical unicorn poo bath bomb. Strawberry
                      aroma, blue and yellow bath-bomb that magically turns the
                      water a sparkling iridescent green.
                      <br />
                      All genders and ages - should appeal to kids and adults
                      looking for a joke present. <br />
                      Bright and funny.
                    </p>
                    <br />
                  </div>
                </Slide>
                <Slide index={1}>
                  <img src={hempoil} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Hemp Oil</h2>
                    <p>
                      Vintage look for package and label design on brown kraft
                      paper applied to an amber glass bottle for CBD oil, hemp
                      oil. <br />
                      Design is simply black ink on a light-brown background.
                    </p>
                  </div>
                </Slide>
                <Slide index={2}>
                  <img src={dylonic} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Dylonic</h2>
                    <p>
                      Unique Packaging for Exfoliating Brush.
                      <br />
                      Made for online sale and to be placed on shelfs in
                      cosmetic stores.
                      <br />
                    </p>
                  </div>
                </Slide>
                <Slide index={3}>
                  <img src={dapper} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Whiskey Stone & Glass gift set</h2>
                    <p>
                      Box containing whiskey glasses and granite cube stones
                      used to chill the liquids in the glasses.
                      <br />
                      Aimed towards modern day "dapper" style men. <br />
                      Beards, clean hair, classic and timeless gentlemen styles
                      with a modern flair.
                    </p>
                  </div>
                </Slide>
                <Slide index={4}>
                  <img src={rviita} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Rviita Energy Tea</h2>
                    <p>
                      Pouch design needed for Rviita energy tea - a premium,
                      healthy afternoon pick-me-up beverage. <br />
                      It is made for professionals, athletes, and
                      health-conscious consumers.
                    </p>
                  </div>
                </Slide>
                <Slide index={5}>
                  <img src={edisonbulb} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Vintage Edison LED Bulbs</h2>
                    <p>
                      Clean and simple but it also have a modern twist with a
                      vintage look.
                      <br />
                      Bringing vintage light bulbs to the 21st century.
                    </p>
                  </div>
                </Slide>
                <Slide index={6}>
                  <img src={dsauto} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Drive Shaft Joint Assembly</h2>
                    <p>
                      Package for drive shaft joint assembly, or drive shaft in
                      short.
                      <br />
                      It is a car spare part attached to the gearbox and car
                      tires.
                      <br />
                      Design should have an "engineering" feel, like everything
                      is designed to precision, it's high quality, it's premium.
                    </p>
                  </div>
                </Slide>
                <Slide index={7}>
                  <img src={nfstea} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Not For Sale Tea</h2>
                    <p>
                      Tea bags in a box, the packaging design allows the boxes
                      to be displayed horizontally and vertically.
                      <br /> Easy for the consumer to tell the variants apart,
                      and at the same time create a strong coherent impression.
                    </p>
                  </div>
                </Slide>
                <Slide index={8}>
                  <img src={brickshield} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Brick Shield Lego Spray</h2>
                    <p>
                      Retail box design for BrickShield Brick Glue Adhesive
                      Spray that makes bonding legos a breeze.
                      <br />
                      Using the same relative theme as the spray can but
                      transfer that to a retail box and incorporate bricks into
                      the design.
                    </p>
                  </div>
                </Slide>
                <Slide index={9}>
                  <img src={kaisersax} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Kaiser Sax</h2>
                    <p>
                      Packaging for new product going to launch.
                      <br />
                      12 coloring Pencils.
                      <br />
                      Red and Yellow combination.
                    </p>
                  </div>
                </Slide>
                <Slide index={10}>
                  <img src={alco} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Alco Ventures</h2>
                    <p>
                      Stainless Steel Citrus Press package made to target the
                      cooking enthusiasts and gourmet chefs.
                      <br />
                      Trendy, simple and identifiable line art.
                    </p>
                  </div>
                </Slide>
                <Slide index={11}>
                  <img src={myrepublik} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>My Republik</h2>
                    <p>
                      Stylish packaging for clothing brand manufacturing and
                      retailing adult leisure, casual wear.
                      <br />
                      Classy as well as stand out so that when it`s being
                      carried by the shopper people notice it.
                    </p>
                  </div>
                </Slide>
                <Slide index={12}>
                  <img src={sporttape} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>HELETIC Sporttape </h2>
                    <p>
                      Modern, simple, athletic package design for sporttape that
                      is being used for different applications for preventing or
                      treating injuries.
                      <br />
                      The Sporttape comes in different colors and designs.
                      <br />
                      Main packaging design, which has small details on it to be
                      changed to show the different color or design of the tape.
                    </p>
                  </div>
                </Slide>
                <Slide index={13}>
                  <img src={awesome} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Awesome Snorkel Mask</h2>
                    <p>
                      Box design for snorkeling mask that covers your entire
                      face and allows you to freely breath through our nose
                      and/or mouth and have panoramic views underwater while
                      swimming on the surface.
                      <br />
                      Made for retail shops and 5 star hotel beach stores in the
                      Middle East.
                      <br />
                      Sizing chart to find your size on the back of the box.
                    </p>
                  </div>
                </Slide>
              </Slider>
            </CarouselProvider>
          </div>
        </VizSensor>
        <VizSensor
          partialVisibility
          onChange={(isVisible2) => {
            this.setState({ section2Viz: isVisible2 });
          }}
        >
          <div
            className="port_section logo_design"
            style={{
              opacity: this.state.section2Viz ? 1 : 0,
              transition: "opacity 800ms linear",
            }}
          >
            <h1>Logo Design</h1>
            <CarouselProvider
              className="carousel-package"
              naturalSlideWidth={200}
              naturalSlideHeight={100}
              totalSlides={15}
            >
              <ButtonBack>
                <img src={backButton} alt="" />
              </ButtonBack>

              <ButtonNext>
                <img src={nextButton} alt="" />
              </ButtonNext>
              <Slider>
                <Slide index={0}>
                  <img src={ess} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Elite Smart Systems</h2>
                    <p>
                      Main Factory For Camera ip and cctv and NVR , DVR needed
                      logo design preferring blue color with 3D effects.
                    </p>
                  </div>
                </Slide>
                <Slide index={1}>
                  <img src={valleybrook} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Ron Jaworski's Valleybrook</h2>
                    <p>
                      Logo design for Country Club with Golf, Weddings, and Pub.
                      <br />
                      Mid Range - Not stuffy.
                      <br /> A fun place to golf and wedding or event.
                    </p>
                  </div>
                </Slide>
                <Slide index={2}>
                  <img src={vibrand} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Vibrand</h2>
                    <p>
                      Target to incorporate "V" in the logo for a financial
                      institutions that invest in funds and shares on behalf of
                      the customer.
                    </p>
                  </div>
                </Slide>
                <Slide index={3}>
                  <img src={stelle} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Stelle</h2>
                    <p>
                      Designed for premier seller of general merchandise.
                      Wholesale Stelle Bath Tissue are sold to businesses and
                      consumers all over the world.
                    </p>
                  </div>
                </Slide>
                <Slide index={4}>
                  <img src={table33} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Table 33</h2>
                    <p>
                      Designed for full service breakfast, lunch and coffee bar.
                      <br />
                      This table is not an exclusive table; its made ready for
                      any and all!
                    </p>
                  </div>
                </Slide>
                <Slide index={5}>
                  <img src={happyfamily} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Happy Family</h2>
                    <p>
                      Private label name and logo design to be used on a wide
                      variety of products. <br />
                      The products could be anything you would find in your
                      average grocery store.
                      <br />
                      Design is universal, it will be able to be placed on food
                      items as well as household items.
                    </p>
                  </div>
                </Slide>
                <Slide index={6}>
                  <img src={cann} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Cann Animal</h2>
                    <p>
                      Logo needed fo general health products for dog and cats,
                      to use on bottles and treat packages.
                    </p>
                  </div>
                </Slide>
                <Slide index={7}>
                  <img src={primehook} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Primehook</h2>
                    <p>
                      Information technology consulting and solutions to
                      business and government clients company needed logo with
                      implemented plum and hook that will be stylized, and
                      memorable, something that will convey well on the web,
                      mobile, trade-show booths, powerpoint slides, business
                      cards and letterhead.
                    </p>
                  </div>
                </Slide>
                <Slide index={8}>
                  <img src={trinity} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Trinity</h2>
                    <p>
                      Logo made for Real Estate & Mortgage Company.
                      <br />
                      Represented to faith...on relationships and serving
                      others.
                    </p>
                  </div>
                </Slide>
                <Slide index={9}>
                  <img src={conveyor} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Conveyor Media</h2>
                    <p>
                      Designed for Television and new media producers who
                      specialize in fun and imaginative formats including
                      docu-series, comedy shows and talk/panel.
                    </p>
                  </div>
                </Slide>
                <Slide index={10}>
                  <img src={gulvhallen} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Gulvhallen</h2>
                    <p>
                      This one is for online company selling wooden and laminate
                      flooring products from Norway.
                    </p>
                  </div>
                </Slide>
                <Slide index={11}>
                  <img src={visualq} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Visual Quartet</h2>
                    <p>
                      Made for Entertainment & The Arts company.
                      <br />
                      Cinematography. Motion Graphics. Editing. Storytelling.
                      <br />
                      4 fundamentals of video production.
                      <br />
                    </p>
                  </div>
                </Slide>
                <Slide index={12}>
                  <img src={device} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Device</h2>
                    <p>
                      Logo design for a label on the body of a smart heat
                      radiator/air conditioner. <br />
                      Future smart house. <br />
                      Autonomous smart house elements, operated without any
                      control unit, remote control or even a smartphone.
                    </p>
                  </div>
                </Slide>
                <Slide index={13}>
                  <img src={magner} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Magner</h2>
                    <p>
                      Logo for construction company that includes many different
                      departments including: Scaffold, Roofing, General
                      Construction, Responsive maintenance.
                    </p>
                  </div>
                </Slide>
                <Slide index={14}>
                  <img src={tamnun} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Tamnun Networks</h2>
                    <p>
                      Network Management and Security Software System that
                      enables the Network Administrator to quickly identify
                      critical and other relevant events throughout the Network
                      and respond in real time..
                    </p>
                  </div>
                </Slide>
              </Slider>
            </CarouselProvider>
          </div>
        </VizSensor>
        <VizSensor
          partialVisibility
          onChange={(isVisible3) => {
            this.setState({ section3Viz: isVisible3 });
          }}
        >
          <div
            className="port_section web_design"
            style={{
              opacity: this.state.section3Viz ? 1 : 0,
              transition: "opacity 800ms linear",
            }}
          >
            <h1>Web Design</h1>
            <CarouselProvider
              className="carousel-package"
              naturalSlideWidth={200}
              naturalSlideHeight={100}
              totalSlides={4}
            >
              <ButtonBack>
                <img src={backButton} alt="" />
              </ButtonBack>

              <ButtonNext>
                <img src={nextButton} alt="" />
              </ButtonNext>
              <Slider>
                <Slide index={0}>
                  <img src={avainsu} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Ava Insurance</h2>
                    <p>
                      Web site design for insurance brokers for business,
                      household, van, fleet, property owners, liability,
                      commercial combined.
                      <br />
                      User friendly and attractive sleeak and easy.
                    </p>
                  </div>
                </Slide>
                <Slide index={1}>
                  <img src={moov} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Moov</h2>
                    <p>
                      Web site for Yoga nutrition products.
                      <br />
                      My first web design project for getting certificated from
                      Jimdo website for Website Contests.
                    </p>
                  </div>
                </Slide>
                <Slide index={2}>
                  <img src={proels} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>ProELS</h2>
                    <p>
                      Splash screen/loading screen for a web application for
                      dispatching of emergency rescue teams and vehicles
                      (ambulances).
                    </p>
                  </div>
                </Slide>
                <Slide index={3}>
                  <img src={metus} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Metus</h2>
                    <p>Metus web site design XD prototip.</p>
                  </div>
                </Slide>
              </Slider>
            </CarouselProvider>
          </div>
        </VizSensor>
        <VizSensor
          partialVisibility
          onChange={(isVisible4) => {
            this.setState({ section4Viz: isVisible4 });
          }}
        >
          <div
            className="port_section web_app"
            style={{
              opacity: this.state.section4Viz ? 1 : 0,
              transition: "opacity 800ms linear",
            }}
          >
            <h1>Web Applications</h1>
            <CarouselProvider
              className="carousel-package"
              naturalSlideWidth={200}
              naturalSlideHeight={100}
              totalSlides={4}
            >
              <ButtonBack>
                <img src={backButton} alt="" />
              </ButtonBack>

              <ButtonNext>
                <img src={nextButton} alt="" />
              </ButtonNext>
              <Slider>
                <Slide index={0}>
                  <img src={expenses} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Expenses Calculator</h2>
                    <p>
                      Expensese Calculator is application for adding products
                      from daily purchases and calculate the expenses you made,
                      you can sort/filter the items(products) and also view all
                      expenses made with sub total.
                      <br />
                      DB with products and Users - authorisation for Registered
                      Users.
                      <br />
                      Build with MERN stack.
                    </p>
                  </div>
                </Slide>
                <Slide index={1}>
                  <img src={dogapi} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Dog Breed Api</h2>
                    <p>
                      API test working - with login and register forms(db
                      included, auth and admin access).
                      <br />
                      Login or Register to fetch the API.
                      <br />
                      NODE.js and MongoDB used.
                    </p>
                  </div>
                </Slide>
                <Slide index={2}>
                  <img src={courses} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Frontend Courses</h2>
                    <p>
                      Simple REACT Web Application for listing courses from
                      existing array and option to make your custom course that
                      is added in the array and displayed.
                    </p>
                  </div>
                </Slide>
                <Slide index={3}>
                  <img src={magic} width="500px" alt="" />
                  <div className="desc-carousel">
                    <h2>Magic The Gathering</h2>
                    <p>
                      Two paged application for preview and searching MGT cards.
                      Home page with user name input and simple validation.
                      Second page with API fetch, listed cards and search/filter
                      options.
                    </p>
                  </div>
                </Slide>
              </Slider>
            </CarouselProvider>
          </div>
        </VizSensor>
      </div>
    );
  }
}

export default Portfolio;
