import React, { Component } from "react";
import { Link } from "react-scroll";
import LogoWhite from "../../src/LogoWhite.svg";

class Navbar extends Component {
  state = {};
  render() {
    window.onscroll = function() {
      scrollFunction();
      hideArrow();
      showAnimate();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 830 ||
        document.documentElement.scrollTop > 830
      ) {
        document.getElementById("navbarr").style.boxShadow =
          " 4px 2px 5px #f15a24";
        document.getElementById("navbarr").style.background = "#161616";
        document.getElementById("navbarr").style.paddingTop = "10px";
        document.getElementById("navbarr").style.opacity = "0.9";
        document.getElementById("navbar_ul").style.color = "#fff";
        document.getElementById("logo_white").style.display = "inline";
        document.getElementById("logo_white").style.marginLeft = "120px";
      } else {
        document.getElementById("navbarr").style.boxShadow = "none";
        document.getElementById("navbarr").style.background = "none";
        document.getElementById("navbarr").style.paddingTop = "40px";
        document.getElementById("navbar_ul").style.color = "#44aeeb";
        document.getElementById("logo_white").style.display = "none";
      }
    }

    function hideArrow() {
      if (
        document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150
      ) {
        document.getElementById("arrow_down").style.display = "none";
      } else {
        document.getElementById("arrow_down").style.display = "block";
      }
    }

    function showAnimate() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("line1").style.display = "block";
        document.getElementById("line2").style.display = "block";
        document.getElementById("line3").style.display = "block";
        document.getElementById("line4").style.display = "block";
      } else {
        document.getElementById("line1").style.display = "none";
        document.getElementById("line2").style.display = "none";
        document.getElementById("line3").style.display = "none";
        document.getElementById("line4").style.display = "none";
      }
    }

    return (
      <div>
        <div className="menu-wrap" id="menu-ham">
          <input type="checkbox" className="toggler" id="check-tog" />
          <div className="hamburger">
            <div></div>
          </div>
          <div className="menu">
            <div>
              <div>
                <ul id="menu_ul">
                  <li>
                    <Link
                      activeClass="active"
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      about
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="portfolio"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      portfolio
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="footer"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar" id="navbarr">
          <Link
            activeClass="active"
            to="first"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <img id="logo_white" src={LogoWhite} alt="logo" />
          </Link>

          <ul id="navbar_ul">
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              about
            </Link>
            <Link
              activeClass="active"
              to="portfolio"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              portfolio
            </Link>
          </ul>
        </div>
      </div>
    );
  }
}

export default Navbar;
