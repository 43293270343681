import React, { Component } from "react";

import "./MainSkills.css";

class MainSkills extends Component {
  state = {
    skillsViz: false
  };

  render() {
    return (
      <div className="main-skills">
        <div className="top-triangle"></div>
        {/* <span className="border-right-orange"></span> */}

        <div className="right-element">
          <h1 id="main">Main Skills</h1>
          <h2>Design:</h2>
          <h3>AI PS</h3>
          <span className="line color1 " id="line1"></span>
          <h2>Front-End:</h2>
          <h3>HTML CSS JS REACT.js</h3>
          <span className="line color2 " id="line2"></span>
          <h2>Back-End:</h2>
          <h3>NODE.js Express MongoDB MySQL</h3>
          <span className="line color3 " id="line3"></span>
          <h2>Other:</h2>
          <h3>PR XD </h3>
          <span className="line color4 " id="line4"></span>
        </div>
      </div>
    );
  }
}
export default MainSkills;
