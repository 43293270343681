import React, { Component } from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import First from "./sections/First/First";
import MainSkills from "./sections/MainSkills/MainSkills";
import About from "./sections/About/About";
import Portfolio from "./sections/Portfolio/Portfolio";
import Footer from "./sections/Footer/Footer";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar />
        <First />

        <MainSkills />

        <About />
        <Portfolio />
        <Footer />
      </div>
    );
  }
}
export default App;
